<template>
	<div id="setManager">
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			ref="form"
			label-position="top"
			:rules="rules"
			class="organization-add"
		>
			<Row :gutter="32">
				<Col span="6">
					<FormItem label="类型" prop="type">
						<span>{{ formData.typeName }}</span>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="全称" prop="name">
						<Input
							v-if="canEdit"
							v-model="formData.name"
							placeholder="请输入组织名称"
						/>
						<span v-else>{{ formData.name || "--" }}</span>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="简称" prop="nickname">
						<Input
							v-if="canEdit"
							v-model="formData.nickname"
							placeholder="请输入简称"
						/>
						<span v-else>{{ formData.nickname || "--" }}</span>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="联系人" prop="contact">
						<Input
							v-if="canEdit"
							v-model="formData.contact"
							placeholder="请输入联系人"
						/>
						<span v-else>{{ formData.contact || "--" }}</span>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="联系电话" prop="phone">
						<Input
							v-if="canEdit"
							v-model="formData.phone"
							placeholder="请输入联系电话"
						/>
						<span v-else>{{ formData.phone || "--" }}</span>
					</FormItem>
				</Col>

				<Col span="6">
					<FormItem label="Email" prop="email">
						<Input
							v-if="canEdit"
							v-model="formData.email"
							placeholder="请输入Email"
						/>
						<span v-else>{{ formData.email || "--" }}</span>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="网址" prop="address">
						<Input
							v-if="canEdit"
							v-model="formData.address"
							placeholder="请输入网址"
						/>
						<span v-else>{{ formData.address || "--" }}</span>
					</FormItem>
				</Col>
				<Col span="12" v-if="formData.type === '104'">
					<FormItem label="LOGO" prop="logo">
						<Upload
							v-if="canEdit"
							:before-upload="handleBeforeUpload"
							:show-upload-list="false"
							accept="image/jpg, image/jpeg, image/png"
							action="/"
						>
							<template v-if="formData.logo">
								<p style="margin-bottom: 10px">
									<Button>修改LOGO</Button>
								</p>
								<img :src="formData.logo" class="preview" />
							</template>
							<div class="upload-btn-wrap" v-else>
								<Button type="primary" shape="circle" icon="md-add"></Button>
								<p>上传LOGO</p>
							</div>
						</Upload>
						<span v-else>
							<img
								v-if="formData.logo"
								style="max-width: 100%"
								:src="formData.logo"
							/>
							<span v-else>--</span>
						</span>
					</FormItem>
					<p v-if="fileReading" style="color: red">文件读取中......</p>
				</Col>
				<Col span="12" v-if="isShowBusinessLicence">
					<FormItem
						prop="businessLicence"
						:required="true"
						:show-message="false"
						label="营业执照"
					>
						<Row :gutter="32" v-if="canEdit">
							<Col
								:span="12"
								class="img-preview-wrap"
								v-for="(item, index) in formData.businessLicence"
								:key="index"
							>
								<img :src="getPreviewImg(item)" class="preview" />
								<Icon type="md-close" @click="delBusinessLicenceImg(index)" />
							</Col>
							<Col :span="12">
								<Upload
									style="display: inline-block; width: 100%"
									action="/"
									accept="image/jpg, image/jpeg, image/png"
									:show-upload-list="false"
									:before-upload="file => getMD5(file, 'businessLicence')"
								>
									<div
										class="upload-btn-wrap"
										v-if="
											!formData.businessLicence ||
											formData.businessLicence.length < 6
										"
									>
										<Button
											type="primary"
											shape="circle"
											icon="md-add"
										></Button>
										<p>上传营业执照</p>
									</div>
								</Upload>
							</Col>
						</Row>
						<Row v-else>
							<Col span="24">
								<Row :gutter="32" v-if="formData.businessLicence.length">
									<Col
										:span="12"
										v-for="(item, index) in formData.businessLicence"
										:key="index"
									>
										<img
											style="width: 100%; height: 210px"
											:src="getPreviewImg(item)"
											class="preview"
										/>
									</Col>
								</Row>
								<div class="upload-btn-wrap" v-else>
									<p>--</p>
								</div>
							</Col>
						</Row>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				v-if="!canEdit"
				:list="extraList"
				:data="formData"
				formType="view"
			></ExtraForm>
			<ExtraForm
				v-else
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				@updateList="updateList"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div v-if="!canEdit" class="editor-footer">
			<Button type="primary" style="margin-right: 8px" @click="onclick"
				>修改
			</Button>
		</div>
		<div v-else class="editor-footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button
				type="primary"
				@click="onOk"
				:disabled="fileReading"
				:loading="loading"
				>确定
			</Button>
		</div>
	</div>
</template>

<script>
import api from "@/api/setting/organization"
import { formDataToMD5 } from "@/utils/util"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

const { apiGet, apiUpdate, uploadFile } = api

export default {
	name: "setManager",
	mixins: [ExtraMixin],
	components: {
		ExtraForm
	},
	data() {
		return {
			canEdit: false, // 是否是修改状态
			updateId: this.$store.state.user.info.orgId,
			loading: false,
			formData: {
				businessLicence: []
			},
			file: null,
			rules: {
				name: {
					required: true,
					message: "组织名称不能为空",
					trigger: "blur"
				},
				type: {
					required: true,
					message: "组织类型不能为空",
					trigger: "change"
				},
				phone: {
					...this.$rules.mobileNumber,
					message: "手机号码格式不正确",
					trigger: "blur"
				},
				email: [
					{
						type: "email",
						message: "邮箱格式不正确",
						trigger: "blur"
					}
				],
				address: {
					...this.$rules.address,
					message: "输入格式例如：http://www.baidu.com",
					trigger: "blur"
				}
			},
			fileReading: false,
			extraList: [],
			isShowBusinessLicence: false
		}
	},
	created() {
		this.getExtraList("ORG")
	},
	mounted() {
		this.getData()
	},
	methods: {
		/* 根据id获取详情信息 */
		getData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGet({ id: this.updateId })
				if (res && res.data) {
					this.isShowBusinessLicence =
						["101", "102", "103"].indexOf(res.data.type) > -1
					this.formData = {
						...this.formData,
						...res.data,
						businessLicence: res.data.businessLicence
							? res.data.businessLicence.split(",")
							: [],
						...JSON.parse(res.data.extraData)
					}
				}
				this.loading = false
			})
		},
		/* 点击修改按钮 */
		onclick() {
			this.canEdit = true
		},
		onCancel() {
			this.canEdit = false
			this.$emit("onCancel")
		},
		onOk() {
			if (["101", "102", "103"].indexOf(this.formData.type) > -1) {
				if (
					!this.formData.businessLicence.length &&
					this.type !== "personalInfo"
				) {
					this.$Message.error("请上传营业执照！")
					return false
				}
			}

			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						this.formData.extraData = null
						res = await apiUpdate({
							...this.formData,
							id: this.updateId,
							businessLicence: this.formData.businessLicence.join(","),
							extraData: this.getExtraData()
						})
						if (res) {
							this.$Message.success("操作成功！")
							this.canEdit = false
						}
						this.loading = false
					})
				}
			})
			return true
		},
		handleBeforeUpload(file) {
			this.file = file
			const reader = new FileReader()
			this.fileReading = true
			reader.readAsDataURL(file)
			reader.onload = e => {
				this.formData.logo = e.target.result
			}
			reader.onloadend = () => {
				this.fileReading = false
			}
			return false
		},
		updateList(obj) {
			this.extraList = obj
		},
		// md5
		getMD5(file, type) {
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					const businessLicence = [...this.formData.businessLicence]
					businessLicence.push(res.data)
					this.$set(this.formData, "businessLicence", businessLicence)
				}
			})
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		},

		// 删除上传图片
		delBusinessLicenceImg(i) {
			let businessLicence = [...this.formData.businessLicence]
			businessLicence = businessLicence.filter((item, index) => index !== i)
			this.$set(this.formData, "businessLicence", businessLicence)
		}
	}
}
</script>

<style lang="less">
@import "../../../my-theme/color.less";
#setManager {
	padding: 16px;
	height: 100%;
	background-color: #fff;
	.organization-add {
		.file-name {
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding-right: 30px;
			height: 25px;
			line-height: 25px;
			font-size: 14px;
			.close {
				position: absolute;
				right: 0;
				top: 0;
				display: block;
				width: 25px;
				height: 25px;
				text-align: center;
				line-height: 25px;
				cursor: pointer;
				&:hover {
					color: @error-color;
				}
			}
		}
		.ivu-upload-select {
			width: 100% !important;
			.preview {
				max-width: 100%;
			}
		}
		.upload-btn-wrap {
			width: 100%;
			background-color: #f9f9f9;
			padding: 80px 0 50px 0;
			text-align: center;
			cursor: pointer;
			> p {
				margin-top: 15px;
				color: @primary-color;
			}
		}
		.img-preview-wrap {
			display: inline-block;
			position: relative;
			margin-bottom: 15px;
			img {
				width: 100%;
				height: 210px;
			}
			.ivu-icon.ivu-icon-md-close {
				position: absolute;
				left: 450px;
				top: 10px;
				font-size: 20px;
				color: #999;
				cursor: pointer;
				&:hover {
					color: red;
				}
			}
		}
	}
	.editor-footer {
		text-align: right;
	}
}
</style>
